export const environment = {
    production: false,
    apiEndPoint : 'https://integr-api-test.azure.chevron.com', // dynamically will change based on BU Selection
    defaultapiEndPoint : 'https://integr-api-test.azure.chevron.com', // Static URL
    calConfig:{
      tenant: 'chevron.onmicrosoft.com',
      clientId: '72493f66-a6ea-4b13-bda3-629494c969ea',
      postLogoutRedirectUri: 'https://integr-test.azure.chevron.com',
      oidcScopes: ["https://integr-test.azure.chevron.com/user_impersonation"]
    },
    chunkConfig: {
      chunkSize : 1 * 512 * 1024 * 1024,             
      chunkMinimumUploadSize : 4 * 1024 * 1024 * 1024        
    } 
  };
  